$black: #000000;
$white: #ffffff;
$primary1: #ff2953;

@mixin main-font {
  font-family: 'Poppins', sans-serif;
}

@mixin hero_btn($col1, $col2, $pad1, $pad2, $bRadius) {
  display: inline-block;
  padding: $pad1 $pad2;
  background-color: transparent;
  border: 1.5px solid $col1;
  color: $col1;
  border-radius: $bRadius;
  transition: transform 0.3s;

  &:hover {
    background-color: $col1;
    color: $col2;
  }
}

@mixin upperBold {
  text-transform: uppercase;
  font-weight: bold;
}

body {
  @include main-font;
  color: #000000;
  background-color: #ffffff;
}

.layout_padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.layout_padding2 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.layout_padding2-top {
  padding-top: 45px;
}

.layout_padding2-bottom {
  padding-bottom: 45px;
}

.layout_padding-top {
  padding-top: 120px;
}

.layout_padding-bottom {
  padding-bottom: 120px;
}

.heading_container {
  display: flex;
  justify-content: center;
  text-align: center;

  h2 {
    font-weight: bold;
    position: relative;
    padding-bottom: 5px;
    text-transform: uppercase;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 55px;
      height: 5px;
      background-color: $primary1;
      transform: translateX(-50%);
    }
  }
}

/*header section*/
.hero_area {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background: #783445 fixed;
}

/* 모바일 화면의 경우 */
@media (max-width: 768px) {
  .hero_area {
    height: 150vh; /* 모바일 화면에서의 높이 조절 */
  }
}

.sub_page {
  .hero_area {
    height: auto;
  }

  .who_section.layout_padding {
    padding-top: 0;
  }
}

.hero_area.sub_pages {
  height: auto;
}

.header_section {}

.header_section .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.header_section .nav_container {
  margin: 0 auto;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-item {
  .nav-link {
    margin: 10px 30px;
    padding: 0;
    padding-bottom: 3px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-transform: uppercase;
    font-size: 15px;

    &::after {
      display: none;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      border-radius: 5px;
      background-color: $white;
    }
  }

  &.active,
  &:hover {
    a {
      &::after {
        display: block;
        background-color: $primary1;
      }
    }
  }
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.user_option {
  display: flex;
  align-items: center;

  a {
    color: $white;
    margin: 10px 30px;
  }
}

.custom_nav-container .nav_search-btn {
  background-image: url(../../public/static/images/search-icon.png);
  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: 7px;
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
}

.navbar-brand {
  display: flex;
  align-items: center;
  position: relative;

  span {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
    position: relative;
    z-index: 3;
  }

}

.custom_nav-container {
  z-index: 99999;
  padding: 15px 0;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler .navbar-toggler-icon {
  background-image: url(../../public/static/images/menu.png);
  background-size: 55px;
}

/*end header section*/

.slider_section {
  flex: 1;
  display: flex;
  align-items: center;
  color: $white;

  #carouselExampleIndicators {
    width: 100%;
  }

  .row {
    align-items: center;
  }

  .box {
    margin: 125px 0;
  }

  .detail-box {
    text-align: center;

    h1,
    h2,
    h3 {
      text-transform: uppercase;
      font-weight: bold;

    }

    h2 {
      font-size: 2.5rem;
    }

    h1 {
      font-size: 3.5rem;
      font-weight: bold;
      letter-spacing: .5rem;
    }



    p {
      margin-top: 25px;
    }

    a {
      @include hero_btn($white, $black, 8px, 35px, 0px);
      text-transform: uppercase;
      margin-top: 35px;
    }
  }



  #carouselExampleIndicators {
    .carousel-indicators {
      position: unset;
      margin: 0;
      margin-top: 45px;
      align-items: center;

      li {
        width: 14px;
        height: 14px;
        background-color: transparent;
        border: 2px solid $white;
        border-radius: 100%;
        opacity: 1;

        &.active {
          border: 4px solid $white;
        }
      }
    }
  }
}

// end slider section


// us section

.us_section {
  background-size: cover;
  background: #333044 fixed;
  color: $white;

  .us_container {
    padding-top: 25px;
    text-align: center;
  }
}


// end us section

// heathy section

.heathy_section {
  background-size: cover;
  background: #783445 fixed;
  color: $white;
  text-align: center;

  h2 {
    font-weight: bold;
  }

  p {
    margin-top: 35px;
  }

  .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 45px;

    a {
      @include hero_btn($white, $black, 8px, 35px, 0);
      text-transform: uppercase;

    }
  }
}

// end heathy section


// trainer section

.trainer_section {
  background: #333044 fixed;
  background-size: cover;
  color: $white;

  .box {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .name {
      h5 {
        font-weight: bold;
        margin-bottom: 15px;
      }

    }

    .img-box {
      border-radius: 15px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .social_box {
      display: flex;
      justify-content: space-between;
      width: 225px;
      padding: 12px 45px;
      background-color: $white;
      border-radius: 50px;
      margin-top: -25px;
    }
  }
}


// end trainer section


// contact section
.contact_section {
  position: relative;
  background-color: #27223f;
  color: $white;

  .heading_container {
    justify-content: start;

    h2 {
      &::before {
        text-align: left;
        left: 0;
        transform: none;
      }
    }
  }

  .row {
    align-items: center;
  }

  .img-box {
    img {
      width: 100%;
    }
  }



  .form_container {
    padding: 45px 0 45px 15px;

  }

  form {}

  input {
    width: 100%;
    border: none;
    background-color: $white;
    outline: none;
    color: $black;
    margin-top: 25px;
    padding: 12px;

    &::placeholder {
      color: #2a2a2c;
    }

    &.message-box {
      padding: 45px 12px;
    }
  }

  button {
    padding: 10px 65px;
    outline: none;
    border: none;
    color: $white;
    background: #ff2953;
    margin: 45px 0 0 auto;
    text-transform: uppercase;
  }

}

// end contact section





// info section
.info_section {
  background-color: #252233;
}

.info_items {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .item {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .img-box {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

    .detail-box {
      margin-top: 5px;
      color: #fff;
    }
  }
}

.info_items {
  position: relative;

  a {
    position: relative;
  }

  .item {
    .img-box {
      &.box-1 {
        background-image: url(../../public/static/images/youtube.svg);
        background-size: 60px 60px; /* 너비와 높이를 각각 100픽셀로 설정 */
      }

      &.box-2 {
        background-image: url(../../public/static/images/kakao_talk.svg);
        background-size: 60px 60px; /* 너비와 높이를 각각 100픽셀로 설정 */
      }

      &.box-3 {
        background-image: url(../../public/static/images/naver_cafe.svg);
        background-size: 60px 60px; /* 너비와 높이를 각각 100픽셀로 설정 */
      }
    }
  }
}

// end info section




/* footer section*/

.footer_section {
  background-color: #fbfdfd;
  padding: 20px;
  font-weight: 500;
}

.footer_section p {
  color: #292929;
  margin: 0;
  text-align: center;
}

.footer_section a {
  color: #292929;
}

/* end footer section*/