#span-5 {
    color: red;
}

#wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
}

#sillim-addr {
    margin-top: 10px;
}

.heathy_section .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

@media (max-width: 420px) {
    /* 또는 특정 ID가 붙은 요소에 대한 스타일도 지정할 수 있습니다. */
    .heathy_section #sillim-addr {
        font-size: 1.2rem;
    }
}

.us_section .us_container #img-box img {
    width: 30px;
    margin-bottom: 10px;
    margin-right: 15px;
}

.us_section .us_container #img-box span {
    font-size: 25px;
    font-weight: bold;
    color: #ffffff;
}

.heathy_section h2 {
    margin-bottom: 20px;
}

.trainer_section .trainer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%; /* 이 부분은 필요에 따라 조정해야 할 수 있습니다 */
}

.trainer_section .trainer_container #img-box img {
    max-width: 400px; /* 최대 너비를 지정하되 */
    width: 100%; /* 화면 크기에 따라 유동적으로 조정됩니다 */
    height: auto;
}

.contact_section .img-box img {
    width: 100%;
    height: auto;
}